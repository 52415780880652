<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">콜이력 조회</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class>
          <div class="form-inp sm">
            <v-select v-model="dropItems" outlined hide-details
              :readonly="RDOnly">
              <template v-slot:label>
                업체선택
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto" content-class="calendar-modal" color="#FFF">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateRangeText" v-bind="attrs" v-on="on" readonly outlined hide-details
                class="form-inp icon-calendar ml-2" append-icon="svg-calendar" label="통화일자"></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker v-model="dates[0]" no-title dark color="#F7AFAE" name="startDate" @change="startDate"
                :first-day-of-week="1" locale="ko-KR" :day-format="mixin_getDate"></v-date-picker>
              <v-date-picker v-model="dates[1]" dark color="#F7AFAE" no-title show-current="false" :min="dates[0]"
                name="endDate" @change="endDate" :first-day-of-week="1" locale="ko-KR" :day-format="mixin_getDate">
              </v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false">확인</v-btn>
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select v-model="dropItems" outlined hide-details label="통화시간"></v-select>
          </div>
          <v-text-field class="form-inp ml-2" name="name" label="" outlined style="width: 50px">
          </v-text-field>
          <span> ~</span>
          <v-text-field class="form-inp ml-2" name="name" label="" outlined style="width: 50px">
          </v-text-field>
          <v-text-field class="form-inp sm ml-2" name="name" label="고객전화" outlined>
          </v-text-field>
          <v-text-field class="form-inp ml-2" name="name" label="내선" outlined style="width: 50px">
          </v-text-field>
          <v-text-field class="form-inp sm ml-2" name="name" label="상담원명" outlined>
          </v-text-field>
          <div class="form-inp sm ml-2">
            <v-select v-model="dropItems" outlined hide-details label="상담그룹"></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select v-model="dropItems" outlined hide-details label="IN/OUT"></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select v-model="dropItems" outlined hide-details label="콜결과"></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select v-model="dropItems" outlined hide-details label="건수"></v-select>
          </div>

        </div>
        <div class="ml-auto align-self-center">
          <v-btn outlined class="btn-etc2" v-on:click="">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 콜이력조회 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        콜이력조회 목록
        <div class="ml-auto">
          <v-btn outlined small class="btn-point " @click="">
            <v-icon small>mdi-arrow-collapse-down</v-icon>엑셀다운로드
          </v-btn>
          <v-btn outlined small class="btn-point ml-2" @click="">
            <v-icon small>mdi-arrow-collapse-down</v-icon>전체엑셀다운로드
          </v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table dense height="552px" :headers="gridDataHeaders" :items="gridDataText" single-select
          :items-per-page="itemsPerPage" :page.sync="page" hide-default-footer class="grid-default"
          @page-count="pageCount = $event" @click:row="" fixed-header>

        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible" next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"></v-pagination>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import XLSX from "xlsx";
import axios from "axios";
import { mapGetters } from "vuex";
import api from "../../store/apiUtil.js";

export default {
  name: "MENU_M110403", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
  },
  data() {
    return {

      menu1: false,

      dropItems: [],

      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],

      gridDataHeaders: [
        {
          text: "NO",
          value: "",
          align: "center",
          width: "30px",
          sortable: false,
        },
        {
          text: "구분",
          value: "",
          align: "center",
          sortable: false,
          width: "100px",
        },
        { text: "콜결과", value: "", align: "center", sortable: false, width: "100px", },
        { text: "통화시작", value: "", align: "center", sortable: false, width: "100px", },
        { text: "통화시간", value: "", align: "center", sortable: false, width: "100px", },
        { text: "상담요청", value: "", align: "center", sortable: false, width: "100px", },
        { text: "상담시작", value: "", align: "center", sortable: false, width: "100px", },
        { text: "상담시간", value: "", align: "center", sortable: false, width: "100px", },
        { text: "고객전화", value: "", align: "center", sortable: false, width: "100px", },
        { text: "내선", value: "", align: "center", sortable: false, width: "100px", },
        { text: "상담원", value: "", align: "center", sortable: false, width: "100px", },
        { text: "DID", value: "", align: "center", sortable: false, width: "100px", },
        { text: "대표번호", value: "", align: "center", sortable: false, width: "100px", },
        { text: "콜백번호", value: "", align: "center", sortable: false, width: "100px", },
        { text: "ARS경로", value: "", align: "center", sortable: false, width: "100px", },
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,

      RDOnly:false,
    };
  },

  async mounted() {
    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.dropItems = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },

  methods: {
    //시작일자
    startDate(e) {
      this.dates[0] = e;
    },
    //종료일자
    endDate(e) {
      this.dates[1] = e;
    },
    allowedStep: (m) => m % 10 === 0,


  },

  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
  },
};
</script>

<style></style>
